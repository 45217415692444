import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './components/pages/home';


function App() {
  return (
    <div className="App">
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}>
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='*' element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
