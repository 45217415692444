import React from "react";
import { Container, Row, Col } from "react-bootstrap";


function Home(){
    return(
        <>
    <Container>
      <Row>
        <Col sm={12}>
            Home
        </Col>
        </Row>
    </Container>
        </>
    );
}
export default Home;